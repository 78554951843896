import { cn } from "@cyna/components/utils"
import { Slot } from "@radix-ui/react-slot"
import { cva, VariantProps } from "class-variance-authority"
import { forwardRef, ReactNode } from "react"

export const typoVariants = cva("", {
  variants: {
    as: {
      h1: "scroll-m-20 text-[1.6rem] font-semibold tracking-tight",
      h2: "scroll-m-20 text-[1.4rem] font-medium tracking-tight transition-colors first:mt-0",
      h3: "scroll-m-20 text-lg font-medium tracking-tight",
      p: "leading-7 [&:not(:first-child)]:mt-6",
      link: "font-medium text-primary underline underline-offset-4",
      blockquote: "mt-6 border-l-2 pl-6 italic",
      ul: "my-6 ml-6 list-disc [&>li]:mt-2",
    },
  },
  defaultVariants: {
    as: "p",
  },
})

export type TypoProps = VariantProps<typeof typoVariants> & {
  className?: string
}

export const Typo = forwardRef<
  HTMLElement,
  TypoProps & { children: ReactNode }
>(({ as, className, ...otherProps }, ref) => (
  <Slot
    className={cn(typoVariants({ as, className }))}
    ref={ref}
    {...otherProps}
  />
))

Typo.displayName = "Typo"
