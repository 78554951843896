import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs))

export const runOnce = (
  fn: (...args: unknown[]) => void | Promise<void>,
  timeout?: number,
): NodeJS.Timeout =>
  setTimeout(() => {
    void fn()
  }, timeout ?? 0)

export const blockClosePage = (confirmMessage: string) => {
  const action = (e: BeforeUnloadEvent) => {
    // eslint-disable-next-line no-alert
    if (confirm(confirmMessage)) {
      //
    } else {
      e.preventDefault()
    }
  }

  window.addEventListener("beforeunload", action, true)

  return () => {
    window.removeEventListener("beforeunload", action, true)
  }
}
