import { Typo } from "@cyna/components/base/Typo"
import { cn } from "@cyna/components/utils"
import pkg from "@cyna/package"
import { LoaderCircleIcon } from "lucide-react"
import { HTMLAttributes } from "react"

export const Loader = ({
  className,
  ...otherProps
}: { className?: string } & HTMLAttributes<HTMLDivElement>) => (
  <div
    {...otherProps}
    className={cn(
      "bg-background/40 fixed inset-0 z-50 flex w-full flex-col items-center justify-center",
      className,
    )}
  >
    <LoaderSpinner />
  </div>
)

export const LoaderSpinner = ({ className }: { className?: string }) => (
  <LoaderCircleIcon
    className={cn("h-6 w-6 animate-spin", className)}
    role="progressbar"
  />
)

export const LoaderLogo = () => (
  <div className="absolute inset-0 flex flex-col items-center justify-center gap-4">
    <Typo as="h1">
      <h1>Cyna Platform</h1>
    </Typo>
    <LoaderSpinner className="size-8" />
    <span className="mx-auto font-mono text-xs font-normal">
      v.{pkg.version}
    </span>
  </div>
)
